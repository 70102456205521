<template>
  <div id="no-article-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-file-earmark-x"></i>
          <span style="margin-left: 5px"> No Article </span>
        </b-card-title>
        <!-- filter component -->
        <div class="d-flex">
          <!-- tier filter -->
          <select
            v-model="tier"
            class="form-control"
            style="width: 15rem; height: 3rem"
          >
            <option value="">Semua Tier</option>
            <option value="0">Untier</option>
            <option value="1">Tier 1</option>
            <option value="2">Tier 2</option>
            <option value="3">Tier 3</option>
            <option value="4">Tier 4</option>
          </select>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge ml-1" style="width: 20vw">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="name"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component v-else :data="table_data" class="mt-1" />
    </b-card>
  </div>
</template>
<script>
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import datePicker from "@/views/components/datePicker.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "NoArticleLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "loader-component": loader,
    "date-picker-component": datePicker,
  },
  data() {
    return {
      is_loading: true,
      from_date: new Date(),
      to_date: new Date(),
      tier: "",
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      name: null,
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "status",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      cancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getData();
    },
    name() {
      if (this.name.length == 0 || this.name.length > 2) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
    tier() {
      this.updateQuery("page", 1);
      this.getData();
    },
  },
  methods: {
    getData() {
      this.is_loading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      let params = {
        from_date: moment(this.from_date).format("YYYY-MM-DD 00:00:00"),
        to_date: moment(this.to_date).format("YYYY-MM-DD 23:59:59"),
        page: this.page,
        items: this.items,
        name: this.name ? this.name : "",
      };
      if (this.tier) {
        params.tier = this.tier;
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper-log/no-article?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.log_scraper.length > 0) {
            this.table_data.items = res.data.log_scraper;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items.length > 0) {
            this.is_loading = false;
          }
        });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    startSearch() {
      this.updateQuery("page", 1);
      this.getData();
    },
  },
};
</script>
